

.backToTop {
  margin: auto;
  width: auto;
  width: 140px;
  padding: 2px;
}

.backToTop:hover {
  cursor: pointer;
}

.textLarger {
  font-size: larger;
}

.textXLarge {
  font-size: x-large;
}

.headerTitles {
  font-size: 3.4em;
}

.fullSizePage {
  height: 100vh;
}

/* 
################################ 
Intro Section
################################
*/
.landscapeImg {
  position: relative;
  top: -20px;
  display: block;
  margin: auto;
  margin-top: 60px;
  width: 100%;
}

.intro {
  padding: 100px 0 200px 0;
  text-align: center;
  position: relative;
  bottom: 20px;
}

.portfolio {
  margin: auto;
}

/* 
################################ 
About Section
################################
*/
.about {
  margin: auto;
  text-align: center;
  padding: 100px 0 100px 0;
}

/* 
################################ 
Projects Section
################################
*/

.projects {
  margin: auto;
  text-align: center;
  padding: 100px 0 100px 0;
}

.projectImg {
  margin: 10px;
  max-height: 800px;
  min-width: 1000px; 
  object-fit: cover;
}



.floofFinderDescription {
  border: red solid 5px;
}

.stretchGoals {
  text-align: left;
}

.projectBullet > li:not(:first-child) {
  font-size: large;
  /* color: aqua; */
}
.projectBullet > li:first-child {
  font-size: x-large;
  text-decoration: underline;
}

/* 
################################ 
Skills Section
################################
*/

.skills {
  margin: auto;
  /* width: 1000px; */
  padding: 100px 0 100px 0;
}

.skillsSummary:first-child {
  margin-right: 20px;
}


.skillTitle {
  font-size: larger;
}

/* 
################################ 
Friends Section
################################
*/

.friendsCard {
  margin: auto;
  padding: 100px 0 100px 0;
}

.friends {
  margin: auto;
  width: 1100px;
  padding: 100px 0 100px 0;
}

.friendImages {
  width: 240px;
  margin-left: 50px;
  border-radius: 50%;
  border: rgb(0, 0, 0) solid 2px;
  margin-top: 10px;
}

.friendRefer {
  margin: auto;
  padding-top: 20px;
}

.fixBottomSpace {
  margin-bottom: -60px;
  /* border: red solid 5px; */
}