.spaceUnderHeader {
  padding-bottom: 40px;
}

.mainIntro {
  text-align: center;
  margin-bottom: 50px;
  font-size: 3.5em;
}

.mainIntro > span {
  padding-bottom: 1px;
  border-bottom: solid black 5px;
}

.mainProfilePic img {
  outline: 15px solid rgba(238,	233,	212, .75);
  outline-offset: -15px;
}

.MainPic {
  width: 80%;
  float: right;
  border: black solid 3px;
}

.factsLinks {
  text-decoration: none;
}
.facts {
  margin: 10px;
  margin-left: 30px;
}

.facts:first-of-type {
  margin-top: 20px;
}

.mainDescription {
  font-size: larger;
  margin-top: 10px;
}

.factsSection {
  margin-top: 50px;

}

.mainBody {
  margin-bottom: 50px;
}

.coreValues {
  font-size: larger;
  font-weight: 600;
  margin-bottom: 15px;
}

.whoIs {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.imageLink {
  color: rgb(0, 0, 255);
  display: inline-block;
}

.imageLink:hover {
  cursor: pointer;
}

.aboutBio {
  width: 80%;
  margin: auto;
}