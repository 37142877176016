.App {
  height: 100vh;
  font-family: "Alegreya", serif;
  font-family: "Noto Sans", sans-serif;
}

.width1000 {
  width: 1000px;
}

li {
  list-style: none;
}

.marginWidthAuto {
 margin: auto;
 width: auto;
}