@import url(https://fonts.googleapis.com/css?family=Rubik&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* .landing {
  background-color: red;
} */

.mainBody {
  margin: auto;
  padding-top: 10px;
}
header {
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 99;
}

.icon {
  float: left;
  margin: 10px;
  border-radius: 10px;
}

nav {
  position: relative;
  top: 10px;
  bottom: 40px;
  text-align: right;
}

.navListLi {
  margin-right: 10px;
  font-size: larger;
}

.navListLi:last-child {
  margin-right: 50px;
}

.navLinkHeader {
  text-decoration: none;
  color: black;
}


/* .footer {
  background-color: rgb(128, 0, 0);
  color: aliceblue;
  width: 100%;
  position: absolute;
  bottom: -21px;
} */

body {
  background: linear-gradient(0deg, #eee9d4, 50%, #eee9d4);
  font-family: "Rubik", sans-serif;
  /* background: #455a64; */
  /* background: aquamarine; */
  height: 100 !important;
}

.container-fluid {
  position: relative;
  z-index: 10;
  overflow: hidden;
  /* margin-top: 250px; */
  background: #262626;
  color: #627482 !important;
  margin-bottom: 0;
  padding-bottom: 0;
  /* height: 320px; */
}

.footerFontColor {
  color: #627482 !important
}

small {
  font-size: calc(
    12px + (15 - 12) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.bold-text {
  color: #989c9e !important;
}

.mt-55 {
  margin-top: calc(
    50px + (60 - 50) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.h3 {
  font-size: calc(
    34px + (40 - 34) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.social {
  font-size: 21px !important;
}

.rights {
  font-size: calc(
    10px + (12 - 10) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.navLinkFooter {
  color: #627482 !important;
  font-size: medium;
  text-decoration: none;
}

.emailLink {
  color: #627482 !important;
  font-size: medium;
  text-decoration: none;
}

.iconLink {
  color: #989c9e;
}

.productHeader {
  /* border: red solid 5px; */
  margin-top: 60px;
}

.productCardImg {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.productCardImg {
  cursor: pointer;
}

.productCardBox{
  margin: 10px;
}

.productCardBox p {
  height: 70px;
  margin-top: 20px;
}

.productCardImgModal {
  max-width: 900px;
  max-height: 900px;
  margin: auto;
}

.bakingImagesDiv {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 50px;
}
.devInfo {
  margin: auto;
  /* width: 60%; */
}

.bakingTitle {
  text-align: center;
}

.bakingDiv {
  max-width: 1200px;
  margin: auto;
}

.bakerInfo {
  font-size: larger;  
}

.startTag {
  font-size: 1.6em;
  /* margin: 100px ; */
  color: #a44340;
  /* color: #000000; */
}

.backgroundMain {
  margin: auto;
  padding-top: 100px;
  /* width: 60%; */
  z-index: 2;
  position: relative;
  /* color: rgb(197, 196, 184); */
  /* color: #eee9d4 */
}

.wordText {
  /* color: #eee9d4 */
  /* background-image: linear-gradient(to right, #E0F8F7, #000000);
  color: transparent;
  background-clip: text; */
}

/* .mainComponentBody {
  background-image: url('../images/silhouette.jpg');
  background-size: 100%;
  position: fixed;
  background-repeat: repeat;
  padding: 1000px;
} */

.picBorder {
  border: rgb(0, 0, 0) solid 5px;
  padding: 10px;
  background-color: azure;
  margin: 10px;
}

.firstCatCake {
  /* width: 200px; */
  float: right;
  max-height: 250px;
}

.ubeCake {
  float: right;
  max-width: 250px;
}

.kimBirthdayCake {
  max-width: 300px;
  float: right;
}
.portfolio-nav {
  height: 100%;
  width: 15%;
  position: fixed;
  top: 0;
  left: 100;
  margin-top: 60px;
  background-color: rgb(121, 81, 57, 0.6);
  padding-top: 20px;
}

.portListLi {
  margin-bottom: 20px;
  font-size: 2em;
}

.portListLi:hover {
  cursor: pointer;
}

.portList {
  margin-top: 70px;
}

.picNameLinks {
  text-align: center;
}

.profilePic {
  border-radius: 50%;
  border: rgb(54, 47, 47) solid 2px;
  background-color: rgb(54, 47, 47);
  position: relative;
  margin-bottom: 20px;
}

.name {
  font-size: x-large;
  margin-bottom: 20px;
}

.icons {
  background-color: rgb(34, 37, 43);
  border-radius: 50%;
  padding: 7px;
}



.backToTop {
  margin: auto;
  width: auto;
  width: 140px;
  padding: 2px;
}

.backToTop:hover {
  cursor: pointer;
}

.textLarger {
  font-size: larger;
}

.textXLarge {
  font-size: x-large;
}

.headerTitles {
  font-size: 3.4em;
}

.fullSizePage {
  height: 100vh;
}

/* 
################################ 
Intro Section
################################
*/
.landscapeImg {
  position: relative;
  top: -20px;
  display: block;
  margin: auto;
  margin-top: 60px;
  width: 100%;
}

.intro {
  padding: 100px 0 200px 0;
  text-align: center;
  position: relative;
  bottom: 20px;
}

.portfolio {
  margin: auto;
}

/* 
################################ 
About Section
################################
*/
.about {
  margin: auto;
  text-align: center;
  padding: 100px 0 100px 0;
}

/* 
################################ 
Projects Section
################################
*/

.projects {
  margin: auto;
  text-align: center;
  padding: 100px 0 100px 0;
}

.projectImg {
  margin: 10px;
  max-height: 800px;
  min-width: 1000px; 
  object-fit: cover;
}



.floofFinderDescription {
  border: red solid 5px;
}

.stretchGoals {
  text-align: left;
}

.projectBullet > li:not(:first-child) {
  font-size: large;
  /* color: aqua; */
}
.projectBullet > li:first-child {
  font-size: x-large;
  text-decoration: underline;
}

/* 
################################ 
Skills Section
################################
*/

.skills {
  margin: auto;
  /* width: 1000px; */
  padding: 100px 0 100px 0;
}

.skillsSummary:first-child {
  margin-right: 20px;
}


.skillTitle {
  font-size: larger;
}

/* 
################################ 
Friends Section
################################
*/

.friendsCard {
  margin: auto;
  padding: 100px 0 100px 0;
}

.friends {
  margin: auto;
  width: 1100px;
  padding: 100px 0 100px 0;
}

.friendImages {
  width: 240px;
  margin-left: 50px;
  border-radius: 50%;
  border: rgb(0, 0, 0) solid 2px;
  margin-top: 10px;
}

.friendRefer {
  margin: auto;
  padding-top: 20px;
}

.fixBottomSpace {
  margin-bottom: -60px;
  /* border: red solid 5px; */
}
.spaceUnderHeader {
  padding-bottom: 40px;
}

.mainIntro {
  text-align: center;
  margin-bottom: 50px;
  font-size: 3.5em;
}

.mainIntro > span {
  padding-bottom: 1px;
  border-bottom: solid black 5px;
}

.mainProfilePic img {
  outline: 15px solid rgba(238,	233,	212, .75);
  outline-offset: -15px;
}

.MainPic {
  width: 80%;
  float: right;
  border: black solid 3px;
}

.factsLinks {
  text-decoration: none;
}
.facts {
  margin: 10px;
  margin-left: 30px;
}

.facts:first-of-type {
  margin-top: 20px;
}

.mainDescription {
  font-size: larger;
  margin-top: 10px;
}

.factsSection {
  margin-top: 50px;

}

.mainBody {
  margin-bottom: 50px;
}

.coreValues {
  font-size: larger;
  font-weight: 600;
  margin-bottom: 15px;
}

.whoIs {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.imageLink {
  color: rgb(0, 0, 255);
  display: inline-block;
}

.imageLink:hover {
  cursor: pointer;
}

.aboutBio {
  width: 80%;
  margin: auto;
}
.App {
  height: 100vh;
  font-family: "Alegreya", serif;
  font-family: "Noto Sans", sans-serif;
}

.width1000 {
  width: 1000px;
}

li {
  list-style: none;
}

.marginWidthAuto {
 margin: auto;
 width: auto;
}
.App {
  background-color: #eee9d4;
}

.backgroundColorWhite {
  
  background-color: #eee9d4;
}

/* Landing */
/* Not in Use */
.landingBackgroundColor {
  background-color: #c7dcdd;
}

/* Back to Top Buttons - Portfolio page */
.borderColorWhite {
  border: #eee9d4 solid 3px;
}

.borderColorBrown {
  border: rgb(121, 81, 57) solid 3px;
}

/* Portfolio Page */
.backgroundColorPaleWhite {
  background-color: #c7dcdd;
  /* background-color: #eee9d4; */
}
.headerColorOnLight {
  color: rgb(121, 81, 57);
}

.backgroundColorPaleRed {
  background-color: #a44340;
  /* background-color: #C7DCDD; */
}
.headerColorOnDark {
  color: #eee9d4;
  /* color: rgb(121, 81, 57); */
}

header {
  /* background-color: #a44340; */
  /* background-color: #c7dcdd; */
  background-color: #eee9d4;

}
