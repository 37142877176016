header {
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 99;
}

.icon {
  float: left;
  margin: 10px;
  border-radius: 10px;
}

nav {
  position: relative;
  top: 10px;
  bottom: 40px;
  text-align: right;
}

.navListLi {
  margin-right: 10px;
  font-size: larger;
}

.navListLi:last-child {
  margin-right: 50px;
}

.navLinkHeader {
  text-decoration: none;
  color: black;
}

