/* .footer {
  background-color: rgb(128, 0, 0);
  color: aliceblue;
  width: 100%;
  position: absolute;
  bottom: -21px;
} */

@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");

body {
  background: linear-gradient(0deg, #eee9d4, 50%, #eee9d4);
  font-family: "Rubik", sans-serif;
  /* background: #455a64; */
  /* background: aquamarine; */
  height: 100 !important;
}

.container-fluid {
  position: relative;
  z-index: 10;
  overflow: hidden;
  /* margin-top: 250px; */
  background: #262626;
  color: #627482 !important;
  margin-bottom: 0;
  padding-bottom: 0;
  /* height: 320px; */
}

.footerFontColor {
  color: #627482 !important
}

small {
  font-size: calc(
    12px + (15 - 12) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.bold-text {
  color: #989c9e !important;
}

.mt-55 {
  margin-top: calc(
    50px + (60 - 50) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.h3 {
  font-size: calc(
    34px + (40 - 34) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.social {
  font-size: 21px !important;
}

.rights {
  font-size: calc(
    10px + (12 - 10) * ((100vw - 360px) / (1600 - 360))
  ) !important;
}

.navLinkFooter {
  color: #627482 !important;
  font-size: medium;
  text-decoration: none;
}

.emailLink {
  color: #627482 !important;
  font-size: medium;
  text-decoration: none;
}

.iconLink {
  color: #989c9e;
}
