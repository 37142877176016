.portfolio-nav {
  height: 100%;
  width: 15%;
  position: fixed;
  top: 0;
  left: 100;
  margin-top: 60px;
  background-color: rgb(121, 81, 57, 0.6);
  padding-top: 20px;
}

.portListLi {
  margin-bottom: 20px;
  font-size: 2em;
}

.portListLi:hover {
  cursor: pointer;
}

.portList {
  margin-top: 70px;
}

.picNameLinks {
  text-align: center;
}

.profilePic {
  border-radius: 50%;
  border: rgb(54, 47, 47) solid 2px;
  background-color: rgb(54, 47, 47);
  position: relative;
  margin-bottom: 20px;
}

.name {
  font-size: x-large;
  margin-bottom: 20px;
}

.icons {
  background-color: rgb(34, 37, 43);
  border-radius: 50%;
  padding: 7px;
}
